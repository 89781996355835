import React from 'react';

import Timeline, {TimelineData} from '../components/Timeline/Timeline';
import View from '../components/View/View';

const mockData: TimelineData = [
  {
    date: '06-2018 - Present',
    title: 'React Developer',
    subtitle: 'Comarch',
    description: 'Nam maximus blandit metus, sit amet ornare enim congue vel. Cras aliquam porta pharetra. Sed at.',
    badge: {
      color: '#3498DB',
      icon: 'code'
    }
  },
  {
    date: '09-2017 - 05-2018',
    title: 'Junior Front-End Developer',
    subtitle: 'Sarigato',
    description: 'Nam maximus blandit metus, sit amet ornare enim congue vel. Cras aliquam porta pharetra. Sed at.',
    badge: {
      color: '#3498DB',
      icon: 'code'
    }
  },
  {
    date: '08-2016 - 05-2017',
    title: 'Control System Engineer',
    subtitle: 'Control-Service',
    description: 'Nam maximus blandit metus, sit amet ornare enim congue vel. Cras aliquam porta pharetra. Sed at.',
    badge: {
      color: '#FF5F64',
      icon: 'industry'
    }
  },
  {
    date: '10-2013 - 01-2017',
    title: 'Bachelor of Science - Automatic Control and Robotics\n',
    subtitle: 'University of Science and Technology',
    description: 'Nam maximus blandit metus, sit amet ornare enim congue vel. Cras aliquam porta pharetra. Sed at.',
    badge: {
      color: '#48DD85',
      icon: 'graduation-cap'
    }
  }
];

const ExperienceView = () => (
  <View>
    <Timeline data={mockData} />
  </View>
);

export default ExperienceView;
