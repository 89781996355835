import PropTypes from 'prop-types';
import React from 'react';

import Error from '../components/Error/Error';
import Card from '../components/Card/Card';
import View from '../components/View/View';

interface NotFoundView {}

const NotFoundView: React.FC<NotFoundView> = () => {
  return (
    <View>
      <Card>
        <Error message='Not found' status={404} />
      </Card>
    </View>
  );
};

NotFoundView.propTypes = {};

NotFoundView.defaultProps = {};

export default NotFoundView;
