import cn from 'classnames';
import React from 'react';

import './App.scss';

import AppHeader from './AppHeader';
import AppMain from './AppMain';
import AppContent from './AppContent';
import useDarkMode from '../hooks/useDarkMode';
import AppRoutes from '../routes/AppRoutes';

function App() {
  const {darkMode} = useDarkMode();
  const appClassName = cn('app', {dark: darkMode}, {light: !darkMode},);
  return (
    <div className={appClassName}>
      <AppHeader />
      <AppMain>
        <AppContent>
          <AppRoutes />
        </AppContent>
      </AppMain>
    </div>
  );
}

export default App;
