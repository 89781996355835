import PropTypes from 'prop-types';
import React from 'react';
import {Route, Switch} from 'react-router-dom';

import NotFoundView from '../view/NotFoundView';
import LandingPageView from '../view/LandingPageView';
import ExperienceView from '../view/ExperienceView';

interface AppRoutes {}

const AppRoutes: React.FC<AppRoutes> = () => {
  return (
    <>
      <Switch>
        <Route path='/' exact component={LandingPageView} />
        <Route path='/experience' exact component={ExperienceView} />
        <Route path='*' component={NotFoundView} />
      </Switch>
    </>
  );
};

AppRoutes.propTypes = {};

AppRoutes.defaultProps = {};

export default AppRoutes;
