import React from 'react';
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IconProp} from '@fortawesome/fontawesome-svg-core';

import './Timeline.scss';

interface Badge {
  color: string;
  icon: IconProp;
}

interface TimelineElement {
  title: string;
  subtitle: string;
  date: string;
  description: string;
  badge: Badge;
}

const TimelineElement: React.FC<TimelineElement> = ({
  title,
  subtitle,
  date,
  description,
  badge: {color, icon}
}) => (
  <div className='timeline-component__element'>
    <div className='timeline-component__element__line' style={{background: color}} />
    <div className='timeline-component__element__badge' style={{background: color}}>
      <FontAwesomeIcon icon={icon} />
    </div>
    <div className='timeline-component__element__title'>{title}</div>
    <div className='timeline-component__element__subtitle'>{subtitle}</div>
    <div className='timeline-component__element__date' style={{color}}>{date}</div>
    <div className='timeline-component__element__description'>{description}</div>
  </div>
);

export type TimelineData = TimelineElement[];

interface Timeline {
  data: TimelineData;
}

const Timeline: React.FC<Timeline> = ({data}) => {
  return (
    <div className='timeline-component'>
      <div className='timeline-component__line' />
      {data.map((element) => <TimelineElement {...element} />)}
    </div>
  );
};

Timeline.propTypes = {};

Timeline.defaultProps = {};

export default Timeline;
