import PropTypes from 'prop-types';
import React from 'react';

import View from '../components/View/View';
import Card from '../components/Card/Card';
import Row from '../components/Grid/Row';
import Column from '../components/Grid/Column';
import LinkButton from '../components/LinkButton/LinkButton';

const LandingPageView = () => {
  return (
    <View>
      <Row>
        <Column>
          <Card>
            Website under construction
          </Card>
        </Column>
      </Row>
      {/*<Row>*/}
      {/*  <Column md={4}>*/}
      {/*    <Card>*/}
      {/*      A*/}
      {/*    </Card>*/}
      {/*  </Column>*/}
      {/*</Row>*/}
      {/*<Row>*/}
      {/*  <Column>*/}
      {/*    <Card>*/}
      {/*      <LinkButton to='/wrng'>404</LinkButton>*/}
      {/*    </Card>*/}
      {/*  </Column>*/}
      {/*</Row>*/}
      {/*<Row>*/}
      {/*  <Column>*/}
      {/*    <Card>*/}
      {/*      B*/}
      {/*    </Card>*/}
      {/*  </Column>*/}
      {/*</Row>*/}
    </View>
  );
};

LandingPageView.propTypes = {};

LandingPageView.defaultProps = {};

export default LandingPageView;
