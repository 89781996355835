import React from 'react';
import PropTypes from 'prop-types';

interface Button {
  children: React.ReactNode;
  onClick: () => void;
}

const Button: React.FC<Button> = ({
  children,
  onClick
}) => {
    return (
        <div onClick={onClick} style={{cursor: 'pointer', userSelect: 'none', width: 'fit-content'}}>
          {children}
        </div>
    );
};

Button.propTypes = {};

Button.defaultProps = {};

export default Button;
