import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import useDarkMode from '../hooks/useDarkMode';
import Button from '../components/Button/Button';
import LinkButton from '../components/LinkButton/LinkButton';

interface DarkModeButton {}

const DarkModeButton: React.FC<DarkModeButton> = () => {
  const {darkMode, setDarkMode} = useDarkMode();

  const icon = darkMode
    ? <FontAwesomeIcon icon={['fas', 'moon']} color='#E0B500' />
    : <FontAwesomeIcon icon={['fas', 'sun']} color='#E0B500' />;

    return (
      <Button onClick={() => setDarkMode(!darkMode)}>
        {icon}
      </Button>
    );
};

interface AppHeader {}

const AppHeader: React.FC<AppHeader> = () => {
  return (
    <header className='app-header'>
      <div className='app-header__burger'>
        <FontAwesomeIcon icon='bars' />
      </div>
      <div className='app-header__logo'>
        Mateusz Kalinowski
      </div>
      <div className='app-header__right'>
        <div className='app-header__actions'>
          {/*<LinkButton to='/projects'>*/}
          {/*  Projects*/}
          {/*</LinkButton>*/}
          {/*<LinkButton to='/experience'>*/}
          {/*  Experience*/}
          {/*</LinkButton>*/}
        </div>
        <div className='app-header__dark-mode'>
          <DarkModeButton />
        </div>
      </div>
    </header>
  );
};

export default AppHeader;
